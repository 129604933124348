<template>
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="instantWins"
  />
</template>
<script>
  import { instantWins } from '@/config/descriptions/instantWins';
  import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';

  export default {
    name: 'InstantWinsTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'instantWins/instantWins',
            fields: 'instantWins/fields',
            sortableFields: 'instantWins/sortableFields',
            pages: 'instantWins/pages',
            loading: 'instantWins/loading',
            resultCount: 'instantWins/resultCount',
            totalRecords: 'instantWins/totalRecords',
            originalFields: 'instantWins/originalFields',
            searchableAllFields: 'instantWins/searchableAllFields',
            success: 'instantWins/success',
            message: 'instantWins/message',
          },
          actions: {
            fields: 'instantWins/handleFields',
            delete: 'instantWins/handleDeleteInstantWins',
            reset: 'instantWins/handleClearFilter',
            query: 'instantWins/handleGetInstantWinsByQuery',
          },
        },
        settings: {
          describe: { ...instantWins },
          page: { ...instantWinsTexts },
        },
        route: {
          create: 'CreateInstantWin',
          edit: 'EditInstantWin',
          preview: 'PreviewInstantWin',
        },
      };
    },
  };
</script>